import React, { useState } from 'react';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PdfView from './utils/pdfMaking/PdfView';
// import PdfView from './PdfView';

const LhForm = () => {
    const formFields = [
        { name: "pan", label: "PAN Number", type: "text", required: true, pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, message: "Please enter a valid PAN number" },
        { name: "gstNo", label: "GST Number", type: "text", required: true, pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, message: "Please enter a valid GST number" },

        // Consignor Details
        { name: "consignor_name", label: "Consignor Name", type: "text", required: true },
        { name: "consignor_address", label: "Consignor Address", type: "text", required: true },
        { name: "consignor_gst", label: "Consignor GST", type: "text", required: true, pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/ },

        // Consignee Details
        { name: "consignee_name", label: "Consignee Name", type: "text", required: true },
        { name: "consignee_address", label: "Consignee Address", type: "text", required: true },
        { name: "consignee_gst", label: "Consignee GST", type: "text", required: true, pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/ },

        // Invoice Details
        { name: "invoiceNo", label: "Invoice Number", type: "text", required: true },
        { name: "invoiceDate", label: "Invoice Date", type: "date", required: true },

        // Additional Details
        { name: "ewayBill", label: "eWay Bill Number", type: "text", required: true },
        { name: "valueOfGoods", label: "Value of Goods", type: "number", required: true, min: 0 },
        { name: "origin", label: "Origin", type: "text", required: true },
        { name: "destination", label: "Destination", type: "text", required: true },
        { name: "vehicleNumber", label: "Vehicle Number", type: "text", required: true, pattern: /^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/ },
        { name: "modeOfPacking", label: "Mode of Packing", type: "text", required: true },
        { name: "weight", label: "Weight", type: "number", required: true, min: 0 },
        { name: "rate", label: "Rate", type: "number", required: true, min: 0 },
        { name: "amount", label: "Amount", type: "number", required: true, min: 0 },
        {
            name: "GST_Payable_by", label: "GST Payable By", type: "select", required: true,
            options: [
                { value: "Consignor", label: "Consignor" },
                { value: "Consignee", label: "Consignee" },
                { value: "Transporter", label: "Transporter" }
            ]
        }
    ];

    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [currentView, setCurrentView] = useState(1);
    const [pdfData, setPdfData] = useState(null);

    const validate = () => {
        const newErrors = {};
        formFields.forEach(({ name, required, pattern, min, message }) => {
            const value = formData[name];
            if (required && !value) {
                newErrors[name] = message || "This field is required";
            } else if (pattern && value && !pattern.test(value)) {
                newErrors[name] = message || "Invalid format";
            } else if (min !== undefined && value < min) {
                newErrors[name] = message || `Value must be greater than ${min}`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFormData = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: undefined }));
        }
    };

    const handleSubmit = () => {
        if (validate()) {
            setLoading(true);
            const formattedData = {
                pan: formData.pan,
                gstNo: formData.gstNo,
                consignor: {
                    name: formData.consignor_name,
                    address: formData.consignor_address,
                    gst: formData.consignor_gst
                },
                consignee: {
                    name: formData.consignee_name,
                    address: formData.consignee_address,
                    gst: formData.consignee_gst
                },
                invoiceDetails: {
                    invoiceNo: formData.invoiceNo,
                    dated: formData.invoiceDate
                },
                details: {
                    ewayBill: formData.ewayBill,
                    valueOfGoods: formData.valueOfGoods,
                    toBeBilled: formData.amount
                },
                origin: formData.origin,
                destination: formData.destination,
                vehicleNumber: formData.vehicleNumber,
                modeOfPacking: formData.modeOfPacking,
                weight: formData.weight,
                rate: formData.rate,
                amount: formData.amount,
                date: new Date().toLocaleDateString(),
                GST_Payable_by: formData.GST_Payable_by
            };

            setPdfData(formattedData);
            setCurrentView(2);
            setLoading(false);
        }
    };

    const handleDownloadPdf = () => {
        const div = document.getElementById("divToPrint");

        // Set specific dimensions for better quality
        const options = {
            scale: 2,
            useCORS: true,
            allowTaint: true,
            scrollY: -window.scrollY,
            windowWidth: div.scrollWidth,
            windowHeight: div.scrollHeight
        };

        html2canvas(div, options).then((canvas) => {
            const imgData = canvas.toDataURL("image/png", 1.0);
            const pdf = new jsPDF({
                orientation: "portrait",
                unit: "mm",
                format: "a4",
                compress: true
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

            const imgX = (pdfWidth - imgWidth * ratio) / 2;
            const imgY = 0;

            pdf.addImage(
                imgData,
                "PNG",
                imgX,
                imgY,
                imgWidth * ratio,
                imgHeight * ratio,
                undefined,
                'FAST'
            );

            pdf.save("delivery-note.pdf");
        });
    };

    return (
        <div className="min-h-screen bg-gray-900 py-8">
            {currentView === 1 ? (
                <div className="max-w-2xl mx-auto p-6 bg-gray-800 rounded-lg shadow-xl">
                    <h2 className="text-2xl font-bold mb-6 text-white text-center">
                        DeliveryPlus LH Form
                    </h2>
                    <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {formFields.map((field, index) => (
                            <div key={index} className="mb-4">
                                <label className="block text-sm font-medium text-gray-300 mb-1">
                                    {field.label}
                                </label>
                                {field.type === "select" ? (
                                    <select
                                        value={formData[field.name] || ""}
                                        onChange={(e) => handleFormData(field.name, e.target.value)}
                                        className="w-full bg-gray-700 border border-gray-600 rounded-md p-2 text-white focus:ring-2 focus:ring-blue-500"
                                    >
                                        <option value="">Select {field.label}</option>
                                        {field.options.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <input
                                        type={field.type}
                                        value={formData[field.name] || ""}
                                        onChange={(e) => handleFormData(field.name, e.target.value)}
                                        className="w-full bg-gray-700 border border-gray-600 rounded-md p-2 text-white focus:ring-2 focus:ring-blue-500"
                                        placeholder={`Enter ${field.label}`}
                                    />
                                )}
                                {errors[field.name] && (
                                    <p className="text-red-500 text-xs mt-1">{errors[field.name]}</p>
                                )}
                            </div>
                        ))}
                    </form>
                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={handleSubmit}
                            disabled={isLoading}
                            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            {isLoading ? "Processing..." : "Generate PDF"}
                        </button>
                    </div>
                </div>
            ) : (
                <div className="max-w-4xl mx-auto">
                    <div className="mb-4">
                        <button
                            onClick={handleDownloadPdf}
                            className="px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                            Download PDF
                        </button>
                        <button
                            onClick={() => setCurrentView(1)}
                            className="ml-4 px-6 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        >
                            Edit Form
                        </button>
                    </div>
                    <div className="pdf-container" style={{ width: '210mm', margin: '0 auto' }}>
                        <div className="bg-white rounded-lg shadow-xl">
                            <PdfView data={pdfData} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LhForm;