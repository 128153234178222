import React, { useState, useEffect, useCallback } from "react";
import { ArrowLeft, CircleCheckBig, XCircleIcon, CheckCircle, XCircle, Clock } from "lucide-react";
import Home from "../VendorVehicle/Home";
import { API_URL } from "../../config";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "sonner";
import { getBase64FromDataURL } from "../Cod/helper";
// import SignatureCanvas from 'react-signature-canvas'

const initialFormData = {
    // step-1
    name: "",
    phone_number: "",
    state: "",
    // step-2
    passbook_image: "",
    rc_image: "",
    pan_card_number: "",
    pan_card_image: "",
    aadhaar_card_number: "",
    aadhaar_card_image: "",
    // step-3
    vehicle_mode: "",
    vehicle_rate: "",
    fixed_rate: "",
    number_of_vehicles: "",
    extra_rate_per_kms: "",
    penalty: "",
    // step-4
    ifsc_code: "",
    account_number: '',
    account_holder_name: '',
    bank_name: '',
};

const TimelineItem = ({ title, status, message }) => {
    const getStatusIcon = () => {
        switch (status) {
            case 'complete':
                return <CheckCircle className="w-5 h-5 text-green-500" />;
            case 'error':
                return <XCircle className="w-5 h-5 text-red-500" />;
            default:
                return <Clock className="w-5 h-5 text-yellow-500 animate-spin" />;
        }
    };

    return (
        <div className="flex items-center mb-4">
            <div className="flex-shrink-0 mr-2">
                {getStatusIcon()}
            </div>
            <div className="flex-grow">
                <p className={`text-sm ${status === 'complete' ? 'text-green-500' : status === 'error' ? 'text-red-500' : 'text-yellow-500'}`}>
                    {title}
                </p>
                {message && status === 'error' && (
                    <p className="text-xs text-red-400 mt-1">{message}</p>
                )}
            </div>
        </div>
    );
};

const Timeline = ({ children }) => {
    return (
        <div className="border-l-2 border-gray-700 pl-4">
            {children}
        </div>
    );
};

const GroundLevelForm = () => {
    const BASE_URL = API_URL;
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [error,] = useState(null);
    const [formData, setFormData] = useState(initialFormData);
    const [currentStep, setCurrentStep] = useState(1);
    const [States, setStates] = useState([]);
    const [reviewErrors, setReviewErrors] = useState({});
    const [timelineItems, setTimelineItems] = useState([]);
    // const [data, setData] = useState([]);
    const id = searchParams.get("id");
    // fetch data from params
    useEffect(() => {
        const fetchUserData = async () => {
            if (id) {
                try {
                    const response = await axios.get(`${BASE_URL}/vendor/${id}`, {
                        headers: {
                            "ngrok-skip-browser-warning": "true",
                        },
                    });
                    const userData = response.data.data;
                    console.log("userData", userData);
                    // setFormData(userData)
                    setFormData({
                        ...userData,
                        ifsc_code: userData.vendor_banks?.[0]?.ifsc_code || "", // Setting the first bank's IFSC code as default
                        account_number: userData.vendor_banks?.[0]?.account_number || "",
                        account_holder_name: userData.vendor_banks?.[0]?.account_holder_name || "",
                        bank_name: userData.vendor_banks?.[0]?.bank_name || "",
                    });
                    // setData(userData);
                } catch (err) {
                    toast.error("Failed to fetch Employee data. Please try again.");
                    console.error("Error fetching user data:", err);
                }
            }
            setIsLoading(false);
        };

        fetchUserData();
    }, [searchParams, BASE_URL, id]);

    // ALL STATE OF HUB
    const fetchStates = useCallback(async () => {
        try {
            const response = await axios.get(`${BASE_URL}/hubs/unique?query=state`, {
                headers: {
                    "ngrok-skip-browser-warning": "true",
                },
            });
            console.log("state", response.data.data);

            if (Array.isArray(response.data.data)) {
                setStates(response.data.data); // Ensure setStates is available in scope
            } else {
                console.error("Expected an array, got:", response.data);
            }
        } catch (error) {
            console.error("Error fetching states:", error);
        }
    }, [BASE_URL]);

    // Reset step to 1 when the modal opens
    useEffect(() => {
        fetchStates();
    }, [fetchStates]);

    const validateForm = () => {
        const errors = {};

        if (currentStep === 1) {
            if (!formData.name) {
                errors.name = "Vendor Name is required.";
            }
            if (!formData.phone_number) {
                errors.phone_number = "Phone Number is required.";
            } else if (!/^\d{10}$/.test(formData.phone_number)) {
                errors.phone_number = "Phone Number must be 10 digits.";
            }
            if (!formData.state) {
                errors.state = "State is required.";
            }
        }
        if (currentStep === 2) {
            if (!formData.rc_image) {
                errors.rc_image = "RC Image is required.";
            }
            if (!formData.pan_card_number) {
                errors.pan_card_number = "PAN Card Number is required.";
            } else if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(formData.pan_card_number)) {
                errors.pan_card_number = "Invalid PAN Card Number format.";
            }
            if (!formData.pan_card_image) {
                errors.pan_card_image = "Pan Card Image is required.";
            }
            if (!formData.aadhaar_card_number) {
                errors.aadhaar_card_number = "Aadhaar Card Number is required.";
            } else if (!/^\d{12}$/.test(formData.aadhaar_card_number)) {
                errors.aadhaar_card_number = "Aadhaar Card Number must be 12 digits.";
            }
            if (!formData.aadhaar_card_image) {
                errors.aadhaar_card_image = "Aadhaar Card Image is required.";
            }
        }
        if ((formData?.vehicle_types?.length > 0 && currentStep === 4) ||
            ((!formData?.vehicle_types?.length || formData?.vehicle_types?.length === 0) && currentStep === 3)) {
            // if (currentStep === 4) {
            // {formData?.vehicle_types?.length > 0 ? "Step 4 of 4" : "Step 3 of 3"}
            if (!formData.passbook_image) {
                errors.passbook_image = "PassBook Image is required.";
            }
            if (!formData.bank_name) {
                errors.bank_name = "Bank Name is required.";
            }
            if (!formData.ifsc_code) {
                errors.ifsc_code = "IFSC code is required.";
            }
            if (!formData.account_holder_name) {
                errors.account_holder_name = "Account holder name is required.";
            }
            if (!formData.account_number) {
                errors.account_number = "Account Number is required.";
            }
        }
        // Set the error state
        setReviewErrors(errors);
        return Object.keys(errors).length === 0; // Returns true if no errors
    }

    const resetForm = () => {
        setFormData(initialFormData);
    }

    const handleNextClick = async () => {
        // Validate the form before proceeding
        if (!validateForm()) {
            console.log("validateForm", reviewErrors)
            return;
        }
        setTimelineItems([]);
        try {
            if (currentStep === 4) {
                setTimelineItems(prev => [...prev, { title: 'Vendor on-boarding', status: 'in-progress' }]);
                const response = await handleSubmit();
                console.log("response", response)
                setTimelineItems(prev => prev.map(item =>
                    item.title === 'Vendor on-boarding' ? { ...item, status: 'complete' } : item
                ));
                if (response) {
                    setTimelineItems(prev => [
                        ...prev,
                        { title: 'Vendor Onboard Successfully', status: 'complete' }
                    ]);
                    setCurrentStep(currentStep + 1);
                } else {
                    toast.error("Failed to on-board vendor. Please try again.");
                    setTimelineItems(prev => [
                        ...prev,
                        { title: 'Failed to onboard vendor', status: 'error' }
                    ]);
                }
            } else if (currentStep === 2) {
                if (formData.vehicle_types.length > 0) {
                    setCurrentStep(currentStep + 1);
                } else {
                    setCurrentStep(4);
                }
            }
            else {
                setCurrentStep(currentStep + 1);
            }
        } catch (error) {
            console.log("error", error)
            setTimelineItems(prev => [
                ...prev,
                {
                    title: 'Error while onboarding vendor',
                    status: 'error',
                    message: error.message || 'An unexpected error occurred'
                }
            ]);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value, // Update the specific form field
        }));
    };

    const handleCapturedRCImage = (rc_image) => {
        console.log("Image received from Home-rc:", rc_image);
        setFormData((prevFormData) => ({
            ...prevFormData,
            rc_image: rc_image, // Store the image as data URL
        }));

        console.log("Image captured:", rc_image);
    };

    const handleCapturedBankPassBookImage = (passbook_image) => {
        console.log("Image received from Home-passbook:", passbook_image);
        setFormData((prevFormData) => ({
            ...prevFormData,
            passbook_image: passbook_image, // Store the image as data URL
        }));

        console.log("Image captured:", passbook_image);
    };

    const handleCapturedPanImage = (pan_card_image) => {
        console.log("Image received from Home-pan:", pan_card_image);
        setFormData((prevFormData) => ({
            ...prevFormData,
            pan_card_image: pan_card_image, // Store the image as data URL
        }));

        console.log("Image pan_card_image captured:", pan_card_image);
    };

    const handleCapturedAadharImage = (aadhaar_card_image) => {
        console.log("Image received from Home-aadhar:", aadhaar_card_image);
        setFormData((prevFormData) => ({
            ...prevFormData,
            aadhaar_card_image: aadhaar_card_image, // Store the image as data URL
        }));

        console.log("Image aadhaar_card_number_image captured:", aadhaar_card_image);
    }

    const handleSubmit = async () => {
        console.log("formData.rc_image", formData.rc_image)
        let data = {
            "id": id,
            "name": formData.name,
            "state": formData.state,
            "phone_number": formData.phone_number,
            "rc_image": getBase64FromDataURL(formData.rc_image),
            "pan_card_number": formData.pan_card_number,
            "pan_card_image": getBase64FromDataURL(formData.pan_card_image),
            "aadhaar_card_number": formData.aadhaar_card_number,
            "aadhaar_card_image": getBase64FromDataURL(formData.aadhaar_card_image),
            "passbook_image": getBase64FromDataURL(formData.passbook_image),
            bank_details: {
                "ifsc_code": formData.ifsc_code,
                "account_number": formData.account_number,
                "account_holder_name": formData.account_holder_name,
                "bank_name": formData.bank_name,
            }
        };

        console.log("data", data)

        try {
            const response = await axios.post(
                `${BASE_URL}/vendor/onboard`, // URL for opening
                data, // FormData as payload
                {
                    headers: {
                        "ngrok-skip-browser-warning": "true", // Add headers here
                    },
                }
            );

            // Handle the response for opening as needed
            console.log("Opening response:", response.data);
            const Data = response.data.data;
            return Data;
        }
        catch (err) {
            toast.error("Failed to fetch VendorId And VehicleId. Please try again.");
            console.error("Error fetching user data:", err);
        }
    }

    const renderStepOne = () => (
        <>
            <h3 className="text-xl font-medium mb-4 text-gray-200">
                <div className="flex w-full justify-between items-center">
                    <div className="flex flex-col">
                        <p
                            className="text-sm font-medium text-gray-100 opacity-65"
                            style={{ fontSize: "0.75rem" }}
                        >
                            {formData?.vehicle_types?.length > 0 ? "Step 1 of 4" : "Step 1 of 3"}
                        </p>
                        Review Your Information
                    </div>
                </div>
            </h3>
            {isLoading ? (
                <p className="text-white">Loading user data...</p>
            ) : (
                <>
                    {error && <p className="text-yellow-500 mb-4">{error}</p>}
                    <div className="space-y-4 mb-6">
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-300 mb-1"
                            >
                                Vendor Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Abc"
                                value={formData?.name}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => handleInputChange(e)}
                                className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                            />
                            {reviewErrors.name && (
                                <p className="text-red-500">{reviewErrors.name}</p>
                            )}
                        </div>
                        <div>
                            <label
                                htmlFor="phone_number"
                                className="block text-sm font-medium text-gray-300 mb-1"
                            >
                                Phone Number
                            </label>
                            <input
                                type="number"
                                id="phone_number"
                                name="phone_number"
                                placeholder="1234567890"
                                value={formData?.phone_number}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => handleInputChange(e)}
                                className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                            />
                            {reviewErrors.phone_number && (
                                <p className="text-red-500">{reviewErrors.phone_number}</p>
                            )}
                        </div>
                        <div>
                            <label
                                htmlFor="state"
                                className="block text-sm font-medium text-gray-300 mb-1">
                                State
                            </label>
                            <select
                                name="state"
                                value={formData?.state || ''}
                                onChange={(e) => handleInputChange(e)}
                                // onChange={(e) => handleInputChange('state', e.target.value)}
                                className="block w-full bg-[#1F1F1F] border border-gray-600 rounded-md shadow-sm p-2 text-white"
                            >
                                <option value="" disabled>
                                    Select the State
                                </option>
                                {Array.isArray(States) && States.length > 0 ? (
                                    States.map((item) => (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>
                                        No states available
                                    </option>
                                )}
                            </select>
                            {reviewErrors.state && (
                                <p className="text-red-500">{reviewErrors.state}</p>
                            )}
                            {/* <label
                            htmlFor="state"
                            className="block text-sm font-medium text-gray-300 mb-1"
                        >
                            State
                        </label>
                        <input
                            type="text"
                            id="state"
                            name="state"
                            placeholder="Karnataka"
                            value={formData?.state}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => handleInputChange(e)}
                            className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                        /> */}
                        </div>
                    </div>
                </>
            )}{" "}
            <button
                onClick={handleNextClick}
                className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
            // disabled={isLoading}
            >
                Next
            </button>
        </>
    );

    const renderStepTwo = () => (
        <>
            <h3 className="text-xl font-medium mb-4 text-gray-200">
                <div className="flex w-full justify-between items-center">
                    <div className="flex flex-col">
                        <p
                            className="text-sm font-medium text-gray-100 opacity-65"
                            style={{ fontSize: "0.75rem" }}
                        >
                            {formData?.vehicle_types?.length > 0 ? "Step 2 of 4" : "Step 2 of 3"}
                        </p>
                        Review Your Information
                    </div>
                    <p
                        className={`text-[16px] cursor-pointer font-regular text-gray-100 opacity-65 flex gap-1 items-center`}
                        onClick={() => {
                            setCurrentStep(1);
                        }}
                    >
                        <ArrowLeft size={18} />
                        Back
                    </p>
                </div>
            </h3>
            {isLoading ? (
                <p className="text-white">Loading user data...</p>
            ) : (
                <>
                    {error && <p className="text-yellow-500 mb-4">{error}</p>}
                    <div className="space-y-4 mb-6">
                        <div>
                            <label
                                htmlFor="rc_image"
                                className="block text-sm font-medium text-gray-300 mb-2"
                            >
                                Upload RC
                            </label>
                            <Home onCapture={handleCapturedRCImage} />
                            {reviewErrors.rc_image && (
                                <p className="text-red-500">{reviewErrors.rc_image}</p>
                            )}
                        </div>
                        <div>
                            <label
                                htmlFor="pan_card_number"
                                className="block text-sm font-medium text-gray-300 mb-1"
                            >
                                Pan card Number
                            </label>
                            <input
                                type="text"
                                id="pan_card_number"
                                name="pan_card_number"
                                placeholder="XXXXX1234X"
                                value={formData?.pan_card_number}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => handleInputChange(e)}
                                className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                            />
                            {reviewErrors.pan_card_number && (
                                <p className="text-red-500">{reviewErrors.pan_card_number}</p>
                            )}
                        </div>
                        <div>
                            <label
                                htmlFor="pan_card_image"
                                className="block text-sm font-medium text-gray-300 mb-2"
                            >
                                Upload Pan Card
                            </label>
                            <Home onCapture={handleCapturedPanImage} />
                            {reviewErrors.pan_card_image && (
                                <p className="text-red-500">{reviewErrors.pan_card_image}</p>
                            )}
                        </div>
                        <div>
                            <label
                                htmlFor="aadhaar_card_number"
                                className="block text-sm font-medium text-gray-300 mb-1"
                            >
                                Aadhar card Number
                            </label>
                            <input
                                type="text"
                                id="aadhaar_card_number"
                                name="aadhaar_card_number"
                                placeholder="XXXXXXXXXXXX"
                                value={formData?.aadhaar_card_number}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => handleInputChange(e)}
                                className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                            />
                            {reviewErrors.aadhaar_card_number && (
                                <p className="text-red-500">{reviewErrors.aadhaar_card_number}</p>
                            )}
                        </div>
                        <div>
                            <label
                                htmlFor="aadhar_card_image"
                                className="block text-sm font-medium text-gray-300 mb-2"
                            >
                                Upload Aadhar Card
                            </label>
                            <Home onCapture={handleCapturedAadharImage} />
                            {reviewErrors.aadhaar_card_image && (
                                <p className="text-red-500">{reviewErrors.aadhaar_card_image}</p>
                            )}
                        </div>
                    </div>
                </>
            )}{" "}
            <button
                onClick={handleNextClick}
                className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
            // disabled={isLoading}
            >
                Next
            </button>
        </>
    );

    const renderStepThree = () => (
        <>
            <h3 className="text-xl font-medium mb-4 text-gray-200">
                <div className="flex w-full justify-between items-center">
                    <div className="flex flex-col">
                        <p
                            className="text-sm font-medium text-gray-100 opacity-65"
                            style={{ fontSize: "0.75rem" }}
                        >
                            {formData?.vehicle_types?.length > 0 ? "Step 3 of 4" : "Step 3 of 3"}
                        </p>
                        Review Your Information
                    </div>
                    <p
                        className={`text-[16px] cursor-pointer font-regular text-gray-100 opacity-65 flex gap-1 items-center`}
                        onClick={() => {
                            setCurrentStep(2);
                        }}
                    >
                        <ArrowLeft size={18} />
                        Back
                    </p>
                </div>
            </h3>
            {/* {isLoading ? (
            <p className="text-white">Loading user data...</p>
          ) : ( */}
            <>
                {error && <p className="text-yellow-500 mb-4">{error}</p>}
                {formData?.vehicle_types?.map((el, index) => (
                    <div className="space-y-4 mb-6">
                        <div>
                            <label
                                htmlFor="vehicle_rate"
                                className="block text-sm font-medium text-gray-300 mb-1"
                            >
                                Vehicle No. - {index + 1}
                            </label>
                        </div>
                        <div>
                            <label
                                htmlFor="vehicle_rate"
                                className="block text-sm font-medium text-gray-300 mb-1"
                            >
                                Vehicle Rate
                            </label>
                            <input
                                type="number"
                                id="vehicle_rate"
                                name="vehicle_rate"
                                placeholder="0"
                                value={el?.vehicle_rate}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => handleInputChange(e)}
                                readOnly
                                className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="fixed_rate"
                                className="block text-sm font-medium text-gray-300 mb-1"
                            >
                                Fixed Rate
                            </label>
                            <input
                                type="number"
                                id="fixed_rate"
                                name="fixed_rate"
                                placeholder="0"
                                value={el?.fixed_kms}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => handleInputChange(e)}
                                readOnly
                                className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="type"
                                className="block text-sm font-medium text-gray-300 mb-1"
                            >
                                Type
                            </label>
                            <input
                                type="text"
                                id="type"
                                name="type"
                                placeholder="0"
                                value={el?.type}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => handleInputChange(e)}
                                readOnly
                                className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="extra_rate_per_kms"
                                className="block text-sm font-medium text-gray-300 mb-1"
                            >
                                Extra Rate Per Kms
                            </label>
                            <input
                                type="number"
                                id="extra_rate_per_kms"
                                name="extra_rate_per_kms"
                                placeholder="0"
                                value={el?.extra_rate_per_km}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => handleInputChange(e)}
                                readOnly
                                className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                            />
                        </div>
                        {/* <div>
                        <label
                            htmlFor="penalty"
                            className="block text-sm font-medium text-gray-300 mb-1"
                        >
                            Penalty
                        </label>
                        <input
                            type="number"
                            id="penalty"
                            name="penalty"
                            placeholder="0"
                            value={formData?.penalty}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => handleInputChange(e)}
                            className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                        />
                    </div> */}
                    </div>
                )
                )}
            </>
            {/* )}{" "}   */}
            <button
                onClick={handleNextClick}
                className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
            // disabled={isLoading}
            >
                Next
            </button>
        </>
    );


    const renderStepFour = () => (
        <>
            <h3 className="text-xl font-medium mb-4 text-gray-200">
                <div className="flex w-full justify-between items-center">
                    <div className="flex flex-col">
                        <p
                            className="text-sm font-medium text-gray-100 opacity-65"
                            style={{ fontSize: "0.75rem" }}
                        >
                            {formData?.vehicle_types?.length > 0 ? "Step 4 of 4" : "Step 3 of 3"}
                        </p>
                        Review Your Information
                    </div>
                    <p
                        className={`text-[16px] cursor-pointer font-regular text-gray-100 opacity-65 flex gap-1 items-center`}
                        onClick={() => formData?.vehicle_types?.length > 0 ? setCurrentStep(3) : setCurrentStep(2)}
                    // setCurrentStep(2);
                    >
                        <ArrowLeft size={18} />
                        Back
                    </p>
                </div>
            </h3>
            {/* {isLoading ? (
            <p className="text-white">Loading user data...</p>
          ) : ( */}
            <>
                {error && <p className="text-yellow-500 mb-4">{error}</p>}
                <div className="space-y-4 mb-6">
                    <div>
                        <label
                            htmlFor="passbook_image"
                            className="block text-sm font-medium text-gray-300 mb-2"
                        >
                            Upload Bank Passbook
                        </label>
                        <Home onCapture={handleCapturedBankPassBookImage} />
                        {reviewErrors.passbook_image && (
                            <p className="text-red-500">{reviewErrors.passbook_image}</p>
                        )}
                    </div>
                    <div>
                        <label
                            htmlFor="ifsc_code"
                            className="block text-sm font-medium text-gray-300 mb-1"
                        >
                            IFSC Code
                        </label>
                        <input
                            type="text"
                            id="ifsc_code"
                            name="ifsc_code"
                            placeholder="SBIXXXXXXX"
                            value={formData?.ifsc_code}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => handleInputChange(e)}
                            className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                        />
                        {reviewErrors.ifsc_code && (
                            <p className="text-red-500">{reviewErrors.ifsc_code}</p>
                        )}
                    </div>
                    <div>
                        <label
                            htmlFor="account_number"
                            className="block text-sm font-medium text-gray-300 mb-1"
                        >
                            Account Number
                        </label>
                        <input
                            type="text"
                            id="account_number"
                            name="account_number"
                            placeholder="XXXXXXXXXXXXX"
                            value={formData?.account_number}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => handleInputChange(e)}
                            className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                        />
                        {reviewErrors.account_number && (
                            <p className="text-red-500">{reviewErrors.account_number}</p>
                        )}
                    </div>
                    <div>
                        <label
                            htmlFor="account_holder_name"
                            className="block text-sm font-medium text-gray-300 mb-1"
                        >
                            Account Holder Name
                        </label>
                        <input
                            type="text"
                            id="account_holder_name"
                            name="account_holder_name"
                            placeholder="Abc"
                            value={formData?.account_holder_name}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => handleInputChange(e)}
                            className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                        />
                        {reviewErrors.account_holder_name && (
                            <p className="text-red-500">{reviewErrors.account_holder_name}</p>
                        )}
                    </div>
                    <div>
                        <label
                            htmlFor="bank_name"
                            className="block text-sm font-medium text-gray-300 mb-1"
                        >
                            Bank Name
                        </label>
                        <input
                            type="text"
                            id="bank_name"
                            name="bank_name"
                            placeholder="XXX"
                            value={formData?.bank_name}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => handleInputChange(e)}
                            className={`block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white`}
                        />
                        {reviewErrors.bank_name && (
                            <p className="text-red-500">{reviewErrors.bank_name}</p>
                        )}
                    </div>
                </div>
            </>
            {/* )}{" "}   */}
            <button
                onClick={handleNextClick}
                className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
            // disabled={isLoading}
            >
                Next
            </button>
            {timelineItems.length > 0 && (
                <div className="mt-8" aria-live="polite">
                    <h4 className="text-lg font-medium mb-4 text-gray-200">Progress</h4>
                    <Timeline>
                        {timelineItems.map((item, index) => (
                            <TimelineItem key={index} title={item.title} status={item.status} />
                        ))}
                    </Timeline>
                </div>
            )}
        </>
    );

    // const renderStepFive = () => (
    //     <>
    //         <h3 className="text-xl font-medium mb-4 text-gray-200">
    //             <div className="flex w-full justify-between items-center">
    //                 <div className="flex flex-col">
    //                     <p
    //                         className="text-sm font-medium text-gray-100 opacity-65"
    //                         style={{ fontSize: "0.75rem" }}
    //                     >
    //                         Step 5 of 5
    //                     </p>
    //                     Review Your Information
    //                 </div>
    //                 <p
    //                     className={`text-[16px] cursor-pointer font-regular text-gray-100 opacity-65 flex gap-1 items-center`}
    //                     onClick={() => {
    //                         setCurrentStep(1);
    //                     }}
    //                 >
    //                     <ArrowLeft size={18} />
    //                     Back
    //                 </p>
    //             </div>
    //         </h3>
    //         {/* {isLoading ? (
    //         <p className="text-white">Loading user data...</p>
    //       ) : ( */}
    //         <>
    //             {error && <p className="text-yellow-500 mb-4">{error}</p>}
    //             <div className="space-y-4 mb-6">
    //                 <div>
    //                     <label
    //                         htmlFor="vehicle_image"
    //                         className="block text-sm font-medium text-gray-300 mb-2"
    //                     >
    //                         Upload Your Photo
    //                     </label>
    //                     <Home />
    //                 </div>
    //                 <div>
    //                     <label
    //                         htmlFor="account_number"
    //                         className="block text-sm font-medium text-gray-300 mb-1"
    //                     >
    //                         Signature
    //                     </label>
    //                     <div style={{ border: '2px dotted white', padding: '10px', borderRadius: '5px' }}>
    //                         <SignatureCanvas
    //                             penColor="blue"
    //                             canvasProps={{ width: 500, height: 200 }}
    //                         />
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //         {/* )}{" "}   */}
    //         <button
    //             onClick={handleNextClick}
    //             className="w-full px-4 py-2 bg-white text-black hover:bg-blue-700 rounded-md transition-colors"
    //         // disabled={isLoading}
    //         >
    //             Next
    //         </button>
    //     </>
    // );

    const renderOnSuccess = () => (
        <div className="w-full h-[75vh] flex flex-col gap-28 pt-10 animate-slide-in-right">
            <div className="flex flex-col items-center gap-6">
                <CircleCheckBig className="w-16 h-16 text-white animate-bounce" />
                <p className="text-2xl ">Completed</p>
            </div>
            <p className="text-white text-center">
                You have successfully submitted your Information
            </p>
        </div>
    );

    const renderOnkycSuccess = () => (
        <div className="w-full h-[75vh] flex flex-col gap-28 pt-10 animate-slide-in-right">
            <div className="flex flex-col items-center gap-6">
                <CircleCheckBig className="w-16 h-16 text-white animate-bounce" />
                <p className="text-2xl ">Thank You!</p>
            </div>
            <p className="text-white text-center">
                Your KYC is approved!
            </p>
        </div>
    );

    return (
        <div className="max-w-md mx-auto p-6 bg-[#191919] rounded-lg shadow-xl text-gray-100">
            <h2 className="text-[16px] font-medium mb-6 text-center text-white-400">
                DeliveryPlus - Vendor Onboarding Form
            </h2>
            {formData?.kyc_status === "approved" ? (
                renderOnkycSuccess()
            ) : (
                <>
                    {currentStep === 1 && renderStepOne()}
                    {currentStep === 2 && renderStepTwo()}
                    {currentStep === 3 && (formData?.vehicle_types.length > 0 ? renderStepThree() : null)}
                    {currentStep === 4 && renderStepFour()}
                    {/* {currentStep === 5 && renderStepFive()} */}
                    {currentStep === 5 && renderOnSuccess()}
                </>
            )}

            {currentStep !== 4 && (
                <button
                    onClick={resetForm}
                    className="w-full mt-4 px-4 py-2 text-gray-400 hover:text-red-300 flex items-center justify-center border border-gray-400 rounded-md transition-colors"
                >
                    <XCircleIcon className="w-5 h-5 mr-1" />
                    Reset
                </button>
            )}
        </div>
    );
}

export default GroundLevelForm;