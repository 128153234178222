import React from 'react';
import './App.css'; // Assuming the CSS is saved in App.css

const PdfView = ({ data }) => {
    // Dummy data to simulate the content


    const totalBill = data?.weight * data?.rate;



    return (
        <div id='divToPrint' className="container">
            <div className="grid-container">
                <div className="item1">
                    <table className="header-table">
                        <tbody>
                            <tr>
                                <td className="left bold" style={{ fontSize: '16px', paddingTop: '3%', width: '35%' }}>
                                    Identifyplus Delivery Services Private Limited
                                    <div className="caution-box"> PAN: {data?.pan}<br /> GST No: {data?.gstNo} </div>
                                </td>
                                <td className="center bold" style={{ fontSize: '14px', width: '35%' }}>
                                    Subject to Gurgaon Jurisdiction only <br />
                                    <span style={{ fontSize: '11px', textDecoration: 'underline' }}>ACCOUNTS COPY</span><br />
                                    <span style={{ fontSize: '11px', textDecoration: 'underline' }}>AT OWNERS RISK</span><br />
                                    <span style={{ fontSize: '11px', textDecoration: 'underline' }}>CAUTION</span>
                                    <div className="caution-box"> {`This consignment will not be detained/diverted/rerouted or re-booked without consignee Bank's written permission and will be delivered at the destination.`} </div>
                                </td>
                                <td className="right small-text" style={{ fontSize: '12px', width: '30%' }}>
                                    <span className="bold">Identifyplus Delivery Services Private Limited</span><br /><br />
                                    <div style={{ fontSize: '12px' }}>{data?.consignor?.address}<br /></div><br />
                                    <div style={{ fontSize: '12px' }}>
                                        <span style={{ display: 'block', paddingRight: '35%' }}>Email: example@example.com</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="item2">
                    <table className="invoice-table">
                        <tbody>
                            {[
                                { header: "Issuing Office/Agent", value: data?.origin },
                                { header: "Mode of Packing", value: data?.modeOfPacking },
                                { header: "Invoice No. & Date", value: `${data?.invoiceDetails?.invoiceNo} & ${data?.invoiceDetails?.dated}` },
                                {
                                    header: "GST Payable by", value:
                                        <>
                                            <label className="checkbox-label"><input type="checkbox" checked={data?.GST_Payable_by === "Consignor"} /> Consignor</label>
                                            <label className="checkbox-label"><input type="checkbox" checked={data?.GST_Payable_by === "Consignee"} /> Consignee</label>
                                            <label className="checkbox-label"><input type="checkbox" checked={data?.GST_Payable_by === "Transporter"} /> Transporter</label>
                                        </>
                                },
                                { header: "Remarks", value: "" }
                            ].map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="cell-content">
                                            <div className="header">{item?.header}</div>
                                            <div className="value">{item?.value}</div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="item3">
                    <table className="header-table">
                        <tbody>
                            <tr>
                                <td className="left" style={{ fontSize: '12px', paddingTop: '3%', width: '45%' }}>
                                    Consigner: {data?.consignor?.name}, {data?.consignor?.address} (GSTIN: {data?.consignor?.gst})<br /><br />
                                    Consignee: {data?.consignee?.name}, {data?.consignee?.address} (GSTIN: {data?.consignee?.gst})
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="item4">
                    <div className="caution-box">
                        CONSIGNMENT NOTE<br />
                        No: {data?.gstNo}<br /><br />
                        Date: {data?.invoiceDetails?.dated}
                    </div>
                </div>

                <div className="item5">
                    <table className="lr-table">
                        <tbody>
                            <tr className="header-row">
                                <td className="origin-cell">Origin: {data?.origin}</td>
                                <td className="destination-cell">Destination: {data?.destination}</td>
                                <td className="vehicle-cell">Veh. No.: {data?.vehicleNumber}</td>
                            </tr>

                            {/* Header for weight and charges */}
                            <tr>
                                <th rowSpan={2} className="packages-cell">Packages</th>
                                <th rowSpan={2} className="description-cell">Description (said to contain)</th>
                                <th colSpan={2}>Weight: {data?.weight}</th>
                                <th className="rate-cell">Rate: {data?.rate}</th>
                                <th className="amount-cell">Amount : {data?.amount}</th>
                            </tr>

                            {/* Sub-header for weight */}
                            <tr>
                                <th>Actual</th><th>Charges</th><th colSpan={2}></th>
                            </tr>

                            {/* Content Row */}
                            <tr>
                                <td></td>
                                {/* Description cell */}
                                <td className="description-text">
                                    As Per Invoice Attached<br />
                                    Invoice No : {data?.invoiceDetails?.invoiceNo}<br />
                                    Dated : {data?.invoiceDetails?.dated}<br />
                                    eWAY Bill : {data?.details?.ewayBill}<br />
                                    Value of Goods : {data?.details?.valueOfGoods}
                                </td>

                                {/* Charges cell */}
                                <td colSpan={3}>
                                    <ul className="charges-list">
                                        <li>Freight</li><li>O.Charge</li><li>G.S.T</li><li>Total</li>
                                    </ul>
                                </td>

                                {/* Amount cell */}
                                <td><p>To<br />Be<br />Billed<br /> ₹{totalBill}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Footer Section */}
                <div className="item6">
                    <div className="footer-container">
                        {/* Date Section */}
                        <div className="date-section">
                            Date : {data?.date}
                            {/* Dots for date */}
                        </div>

                        {/* Party Sign */}
                        <div className="party-sign">Party Sign </div>

                        {/* Company Name */}
                        <div className="company-name"> For, Identifyplus Delivery Services<br /> Private Limited</div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PdfView;